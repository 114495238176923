import React from "react";
import Layout from "./layout";
import Navbar from "../navbar";
import SideNav from "../sidenav";
import Footer from "../footer";

export default function Page({ children }) {
    return (
        <Layout
            data-sal="fade"
            data-sal-delay="300"
            data-sal-easing="ease">
            <Navbar />
            <SideNav />
            {children}
            <Footer />
        </Layout>
    );
}