import React from "react";
import { Link } from "gatsby";
import Logo from "../images/logo.png";
import MapSvg from "../images/map-pin.svg";
import MailSvg from "../images/mail.svg";
import PhoneSvg from "../images/phone.svg";

export default function Footer() {
    return (
        <footer className="footer bg-dark text-muted pt-0 pt-lg-5">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                        <div className="footer-col mt-5 pe-4">
                            <img src={Logo} className="footer-logo mb-4" alt="Logo"/>
                            <p className="mb-0">We design and create client-oriented softwares and provide application services to operate and maintain client's
                    softwares. </p>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 col-lg-2">
                        <div className="footer-col mt-5">
                            <h3 className="footer-title text-white mb-4">Services</h3>
                            <ul className="list-unstyled mb-0">
                                <li className="mb-2"><Link to="/#services">Web</Link></li>
                                <li className="mb-2"><Link to="/#services">Mobile</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 col-lg-2">
                        <div className="footer-col mt-5">
                            <h3 className="footer-title text-white mb-4">Company</h3>
                            <ul className="list-unstyled mb-0">
                                <li className="mb-2"><Link to="/">About</Link></li>
                                <li className="mb-2"><Link to="/#services">Services</Link></li>
                                <li className="mb-2"><Link to="/#team">Team</Link></li>
                                <li className="mb-2"><Link to="/#skills">Skills</Link></li>
                                <li className="mb-2"><Link to="/#stats">Stats</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                        <div className="footer-col footer-contact mt-5">
                            <h3 className="footer-title text-white mb-4">Contact us</h3>
                            <div className="mb-3"><img className="me-2" src={MapSvg} alt="Map" /><span>Lapu-Lapu City, 6015 Cebu, Philippines</span></div>
                            <div className="mb-3"><img className="me-2" src={MailSvg} alt="Mail" /><span>+639-3394-66373</span></div>
                            <div><img className="me-2" src={PhoneSvg} alt="Phone" /><span> contact@tpm-apps.com</span></div>
                        </div>
                    </div>
                </div>
                <div className="row footer-credits text-center mt-5 py-3">
                    <div className="col-12 col-md-6">
                        <div className="footer-copy float-md-none float-lg-start">
                            <p className="mb-0 small">&copy; 2021 <Link to="/" className="text-decoration-none">TPM</Link>. All Rights Reserved</p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}