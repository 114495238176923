import React from "react";
import { Link } from "gatsby";
//import Logo from "../images/tpm-logo.png";
import Tpm from "../images/tpm.png";

export default function Navbar() {
    return (
        <nav id="primary-navbar" className="primary-nav navbar navbar-expand-lg navbar-light bg-white fixed-top font-nunito">
            <div className="container px-4 px-sm-0">
                <Link to="/" className="navbar-brand">
                    <img src={Tpm} alt="Tpm" />
                    <div className="sub-logo">
                        <span>TOTAL PROJECT MANAGEMENT</span>
                        <span>Applications Ltd. Inc.</span>
                    </div>
                </Link>
                <div className="collapse navbar-collapse" id="navbarNavDropdown">
                    <ul className="navbar-nav ms-auto">
                        <li className="nav-item">
                            <Link to="/" className="nav-link active" aria-current="page">Home</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/#services" className="nav-link">Services</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/#skills" className="nav-link">Skills</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/#testimonials" className="nav-link">Testimonials</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/#team" className="nav-link">Team</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/#work" className="nav-link">Work</Link>
                        </li>
                    </ul>
                </div>
                <button id="open-sidenav" className="open-sidenav bg-transparent border-0 ms-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#2c333a" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-menu">
                        <line x1="3" y1="12" x2="21" y2="12"></line>
                        <line x1="3" y1="6" x2="21" y2="6"></line>
                        <line x1="3" y1="18" x2="21" y2="18"></line>
                    </svg>
                </button>
            </div>
        </nav>
    );
}