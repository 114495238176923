import React from "react";
import { Helmet } from "react-helmet";
import { withPrefix } from "gatsby";

import "../../css/bootstrap.min.css";
import "../../css/splide.min.css";
import "../../css/style.css";
import Tpm from "../../images/tpm.png";

export default function Layout({ children }) {
    return (
        <div>
            <Helmet>
                <meta charset="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
                <meta name="description" content="" />
                <title>Total Project Management</title>
                <link rel="icon" href={Tpm} sizes="32x32" />
                <link rel="preconnect" href="https://fonts.gstatic.com" />
                <link href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet" />
                <link href="https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,700;0,800;0,900;1,700;1,800;1,900&display=swap" rel="stylesheet" />
                <script defer src={withPrefix('bootstrap.bundle.min.js')}></script>
                <script defer src={withPrefix('main.js')}></script>
            </Helmet>
            {children}
        </div>
    );
}