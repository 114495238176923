import React from "react";
import { Link } from "gatsby";
import Logo from "../images/tpm.png";

export default function SideNav() {
    return (
        <div id="sidenav-wrap">
            <span className="sidenav-backdrop"></span>
            <div id="sidenav" className="sidenav font-nunito bg-dark px-4">
                <Link to="/#" id="close-sidenav" className="close-sidenav text-decoration-none text-white">&times;</Link>
                <div className="sidenav-logo mt-5 pt-5">
                    <Link to="/#" className=""><img src={Logo} alt="Logo" /></Link>
                </div>
                <div className="sidenav-menu mt-5">
                    <ul className="nav flex-column">
                        <li className="nav-item">
                            <Link to="/" className="nav-link px-0 active" aria-current="page">Home</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/#services" className="nav-link px-0">Services</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/#skills" className="nav-link px-0">Skills</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/#testimonials" className="nav-link px-0">Testimonials</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/#team" className="nav-link px-0">Team</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/#work" className="nav-link px-0">Work</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}